import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, TextField } from '@mui/material';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import { getCollectionsComplete, updateData, getDate, searchDataByValue } from '../../assets/js/functions';
import logo from '../../assets/images/login/head.png';
import imagen from '../../assets/images/login/img.png';
import bienvenido from '../../assets/images/login/bienvenido.png';
import texto from '../../assets/images/login/texto.png';
import btn from '../../assets/images/login/btn.png';
import './styles.scss';

export default function Login() {
	const [mx, setMx] = useState('');
	const navigate = useNavigate();

	// useEffect(() => {
	// 	validarRegistro()
	// }, [])

	// const validarRegistro = async ()=>{

	// }

	const validar = async (e) => {
		e.preventDefault();
		const id = uniqid();
		// const data = await getCollectionsComplete(`${process.env.REACT_APP_BD_USUARIOS}/${id}`);
		if (mx === '') {
			Swal.fire({
				title: '¡Espera!',
				text: 'Ingresa tu nombre completo para poder continuar.',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#ad80f7',
			});
			return null;
		} else {
			const data = await searchDataByValue(`${process.env.REACT_APP_BD_USUARIOS}`, 'nombre', mx);
			if (Object.keys(data).length > 0) {
				sessionStorage.setItem('id', data.id);
				updateData(process.env.REACT_APP_BD_USUARIOS, data.id, { ingreso: getDate() })
					.then(() => {
						navigate('/streaming');
					})
					.catch((e) => {
						console.log('Error: ' + e);
					});
			} else {
				sessionStorage.setItem('id', id);
				updateData(process.env.REACT_APP_BD_USUARIOS, id, { id, nombre: mx, ingreso: getDate() })
					.then(() => {
						navigate('/streaming');
					})
					.catch((e) => {
						console.log('Error: ' + e);
					});
			}
			console.log(data);
		}
	};

	return (
		<>
			<div id='login'>
				<img src={logo} alt='logo' className='header-img' />
				{/* <Container> */}
				<Grid container justifyContent='center' alignItems='center' spacing={4}>
					<Grid item xs={12} sm={5} md={5} lg={5} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
						<div className='center'>
							<img src={imagen} alt='img' className='img-izq' />
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={5} data-aos='zoom-in' data-aos-delay='500' data-aos-duration='1000'>
						<div className='contenedor'>
							<Grid container justifyContent='center' alignItems='center' spacing={4}>
								<Grid item xs={12} sm={12} md={8} lg={8}>
									<img src={bienvenido} alt='img' className='full' />
								</Grid>
								<Grid item xs={12} sm={12} md={8} lg={8}>
									<img src={texto} alt='img' className='full' />
								</Grid>
								<Grid item xs={12} sm={12} md={8} lg={8}>
									<TextField label='' variant='filled' fullWidth value={mx} onChange={(e) => setMx(e.target.value)} />
								</Grid>
								<Grid item xs={12} sm={12} md={8} lg={8} className='center'>
									<button onClick={validar}>
										<img src={btn} alt='img' className='full' />
									</button>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
				{/* </Container> */}
			</div>
		</>
	);
}
