// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyC_2exkwM67vUhOzhec2iz_C2H4T7ybWOI',
	authDomain: 'spira-al-aire.firebaseapp.com',
	projectId: 'spira-al-aire',
	storageBucket: 'spira-al-aire.firebasestorage.app',
	messagingSenderId: '251233786940',
	appId: '1:251233786940:web:79c2d6ddb05d0456c93739',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
