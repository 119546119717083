import { useState, useEffect } from 'react';
import { ref, onChildChanged } from 'firebase/database';
import { db } from '../../config/firebase';
import Swal from 'sweetalert2';
import { Container, Grid, Paper, FormControlLabel, RadioGroup, Radio, TextField } from '@mui/material';
import Chat from '../../components/Chat';
import ValidateSession from '../../components/ValidateSession';
import { download_file, downloadFile, getCollectionsComplete, getDate, top, updateData } from '../../assets/js/functions';
import logo from '../../assets/images/streaming/header.png';
import banner from '../../assets/images/streaming/banner.png';
import './styles.scss';

export default function Encuesta() {
	const [campos, setCampos] = useState({});
	const [termino, setTermino] = useState(false);
	const [usuario, setUsuario] = useState({});

	useEffect(() => {
		getInfo();
		top();
	}, []);

	const getInfo = async () => {
		const dataEncuesta = await getCollectionsComplete(`${process.env.REACT_APP_BD_ENCUESTA}/${sessionStorage.getItem('id')}`);
		const dataUsuario = await getCollectionsComplete(`${process.env.REACT_APP_BD_USUARIOS}/${sessionStorage.getItem('id')}`);

		if (Object.keys(dataEncuesta).length > 0) {
			setTermino(true);
		}
		setUsuario(dataUsuario);
	};

	const enviar = () => {
		if (Object.keys(campos).length < 5) {
			Swal.fire({
				title: '¡Espera!',
				text: 'Contesta todas las preguntas para poder continuar.',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#ad80f7',
			});
			return null;
		}
		let json = { ...campos, id: sessionStorage.getItem('id'), fecha: getDate() };
		updateData(process.env.REACT_APP_BD_ENCUESTA, sessionStorage.getItem('id'), json)
			.then(() => {
				setTermino(true);
				// Swal.fire({
				// 	title: '¡Bien hecho!',
				// 	text: 'Gracias por contestar nuestra encuesta de satisfacción.',
				// 	icon: 'success',
				// 	confirmButtonText: 'Cerrar',
				// 	confirmButtonColor: '#ad80f7',
				// });
			})
			.catch((e) => {
				console.log('Error: ' + e);
			});
	};

	return (
		<>
			<ValidateSession>
				<div id='encuesta'>
					<img src={logo} alt='logo' className='header' />

					{termino === false && (
						<Container maxWidth='lg'>
							<div className='separador' />
							<Paper elevation={4} style={{ padding: '15px' }}>
								<Grid container direction='row' justifyContent='stretch' spacing={2}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='pregunta'>1. La duración de nuestra transmisión fue adecuada:</div>
									</Grid>
									<Grid item lg={4} md={4} sm={4} xs={12}>
										<RadioGroup id='opciones' value={campos.pregunta1} onChange={(e) => setCampos({ ...campos, pregunta1: e.target.value })}>
											<FormControlLabel
												value='Completamente de acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Completamente de acuerdo'
											/>
											<FormControlLabel
												value='De acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='De acuerdo'
											/>
											<FormControlLabel
												value='Ni de acuerdo Ni en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Ni de acuerdo Ni en desacuerdo'
											/>
											<FormControlLabel
												value='En desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='En desacuerdo'
											/>
											<FormControlLabel
												value='Totalmente en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Totalmente en desacuerdo'
											/>
										</RadioGroup>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='pregunta'>2. Realizamos una transmisión de calidad:</div>
									</Grid>
									<Grid item lg={4} md={4} sm={4} xs={12} data-aos='zoom-in' data-aos-delay='50' data-aos-duration='1000'>
										<RadioGroup id='opciones' value={campos.pregunta2} onChange={(e) => setCampos({ ...campos, pregunta2: e.target.value })}>
											<FormControlLabel
												value='Completamente de acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Completamente de acuerdo'
											/>
											<FormControlLabel
												value='De acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='De acuerdo'
											/>
											<FormControlLabel
												value='Ni de acuerdo Ni en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Ni de acuerdo Ni en desacuerdo'
											/>
											<FormControlLabel
												value='En desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='En desacuerdo'
											/>
											<FormControlLabel
												value='Totalmente en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Totalmente en desacuerdo'
											/>
										</RadioGroup>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='pregunta'>3. Nuestro contenido fue de interés y entretenido: </div>
									</Grid>
									<Grid item lg={4} md={4} sm={4} xs={12} data-aos='zoom-in' data-aos-delay='50' data-aos-duration='1000'>
										<RadioGroup id='opciones' value={campos.pregunta3} onChange={(e) => setCampos({ ...campos, pregunta3: e.target.value })}>
											<FormControlLabel
												value='Completamente de acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Completamente de acuerdo'
											/>
											<FormControlLabel
												value='De acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='De acuerdo'
											/>
											<FormControlLabel
												value='Ni de acuerdo Ni en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Ni de acuerdo Ni en desacuerdo'
											/>
											<FormControlLabel
												value='En desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='En desacuerdo'
											/>
											<FormControlLabel
												value='Totalmente en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Totalmente en desacuerdo'
											/>
										</RadioGroup>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='pregunta'>4. Te sentiste parte de la transmisión: </div>
									</Grid>
									<Grid item lg={4} md={4} sm={4} xs={12} data-aos='zoom-in' data-aos-delay='50' data-aos-duration='1000'>
										<RadioGroup id='opciones' value={campos.pregunta4} onChange={(e) => setCampos({ ...campos, pregunta4: e.target.value })}>
											<FormControlLabel
												value='Completamente de acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Completamente de acuerdo'
											/>
											<FormControlLabel
												value='De acuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='De acuerdo'
											/>
											<FormControlLabel
												value='Ni de acuerdo Ni en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Ni de acuerdo Ni en desacuerdo'
											/>
											<FormControlLabel
												value='En desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='En desacuerdo'
											/>
											<FormControlLabel
												value='Totalmente en desacuerdo'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Totalmente en desacuerdo'
											/>
										</RadioGroup>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='pregunta'>5. Comparte un comentario adicional: </div>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<TextField fullWidth multiline rows={4} value={campos.pregunta5} onChange={(e) => setCampos({ ...campos, pregunta5: e.target.value })}></TextField>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='pregunta'>6. Te gustaría participar en la siguiente transmisión: </div>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<RadioGroup id='opciones' value={campos.pregunta6} onChange={(e) => setCampos({ ...campos, pregunta6: e.target.value })}>
											<FormControlLabel
												value='Sí'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='Sí'
											/>
											<FormControlLabel
												value='No'
												control={
													<Radio
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#ad80f7',
															},
														}}
													/>
												}
												label='No'
											/>
										</RadioGroup>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<div className='center'>
											<button className='btn-enviar' onClick={() => enviar()}>
												Enviar respuestas
											</button>
										</div>
									</Grid>
								</Grid>
							</Paper>
							<div className='separador' />
						</Container>
					)}
					{termino === true && (
						<Container maxWidth='lg'>
							<Paper elevation={4} style={{ padding: '15px', marginTop: '40px' }}>
								<Grid container direction='row' justifyContent='stretch' spacing={2}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<h1>Gracias por contestar nuestra encuesta de satisfacción</h1>
									</Grid>
								</Grid>
							</Paper>
						</Container>
					)}
				</div>
			</ValidateSession>
		</>
	);
}
